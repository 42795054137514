export const standardColors = {
  black: '#000000',
  blue: '#43C4E0',
  darkerGrey: '#222222',
  darkGrey: '#888888',
  green: '#6EC250',
  midGrey: '#E0E0E0',
  orange: '#FFA800',
  purple: '#8850FF',
  red: '#FF325A',
  white: '#FFFFFF',
};
