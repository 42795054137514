'use client';
import { useEffect, useMemo } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { UserRequest } from '@bloom/codegen/models/UserRequest';
import { UserResponse } from '@bloom/codegen/models/UserResponse';

import { useInfoMessage } from '@bloom/library/components/FlashMessageV2/info-message-context';
import { SharedQueryKeyEnum } from '@bloom/library/components/hooks/interface';
import { AsyncStatusEnum, useFetch } from '@bloom/library/components/hooks/useFetch';
import { PersistStateKeyEnum } from '@bloom/library/constants/persist-state-keys';

import { useAuthentication } from './useAuthentication';

export function useMe() {
  const { get, post } = useFetch();

  const queryClient = useQueryClient();

  const { showErrorMessageFromResponse } = useInfoMessage();

  const { logout } = useAuthentication();

  async function fetchMe() {
    const response = await get<{ user: UserResponse }>(`${process.env.BLOOM_API}/api/users/me`);

    if (response.status === AsyncStatusEnum.SUCCESS) {
      return response.data.user;
    }

    if ([401].includes(response.error.status)) {
      logout();
    }

    return null;
  }

  const { data, isFetching, refetch } = useQuery({
    queryFn: fetchMe,
    queryKey: [SharedQueryKeyEnum.USER_ME],
  });

  useEffect(() => {
    if (data) {
      localStorage.setItem('bloom_active_account_id', data.defaultAccountId);
    }
  }, [data]);

  async function updateMe(request: UserRequest) {
    return await post<{ user: UserResponse }, UserRequest>('/api/users/me', request, {
      headers: { 'x-account': undefined },
    });
  }

  const { isPending: isUpdatingMe, mutateAsync: updateMeMutation } = useMutation({
    mutationFn: updateMe,
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (_, __, context) => {
      queryClient.setQueryData([SharedQueryKeyEnum.USER_ME], context?.previousContact);
    },
    onMutate: async (request: UserRequest) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: [SharedQueryKeyEnum.USER_ME] });
      // Snapshot the previous value
      const previousContact = queryClient.getQueryData([SharedQueryKeyEnum.USER_ME]);

      queryClient.setQueryData<UserResponse | null | undefined>(
        [SharedQueryKeyEnum.USER_ME],
        (oldData) => {
          if (oldData) {
            return { ...oldData, ...request } as UserResponse;
          }
          return oldData;
        }
      );

      return { previousContact };
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [SharedQueryKeyEnum.USER_ME] });
    },
    onSuccess: (res) => {
      if (res.status === AsyncStatusEnum.SUCCESS) {
        queryClient.setQueryData<UserResponse>([SharedQueryKeyEnum.USER_ME], () => res.data.user);
      }

      if (res.status === AsyncStatusEnum.ERROR) {
        showErrorMessageFromResponse(res);
      }
      return res;
    },
  });

  useEffect(() => {
    // Temporary hack until we migrate to react-query
    if (data) {
      localStorage.setItem(PersistStateKeyEnum.ACTIVE_ACCOUNT_ID, data.defaultAccountId);
    }
  }, [data]);

  return useMemo(
    () => ({ isFetching, isUpdating: isUpdatingMe, me: data, refetch, updateMe: updateMeMutation }),
    [data, isFetching, isUpdatingMe, refetch, updateMeMutation]
  );
}
