'use client';

import React, { useState } from 'react';

import {
  autoUpdate,
  flip,
  FloatingPortal,
  FloatingNode,
  FloatingTree,
  FloatingOverlay,
  offset,
  OffsetOptions,
  safePolygon,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useHover,
  UseFloatingOptions,
  useFloatingParentNodeId,
  useInteractions,
  useMergeRefs,
  useRole,
  useFloatingNodeId,
} from '@floating-ui/react';
import { twMerge } from 'tailwind-merge';

export function usePopover(
  props: Partial<
    UseFloatingOptions & {
      enableFlip?: boolean;
      isOverlayActive?: boolean;
      matchParentWidth: boolean;
      offsetProps?: OffsetOptions;
      root?: HTMLElement | null;
      triggerEvent?: 'hover' | 'click';
    }
  >
) {
  const {
    enableFlip = true,
    isOverlayActive,
    matchParentWidth = false,
    offsetProps,
    onOpenChange: setControlledOpen,
    open: controlledOpen,
    placement = 'bottom',
    root,
    triggerEvent = 'click',
  } = props;

  const nodeId = useFloatingNodeId();

  const [isOpen, setState] = useState(false);

  const open = controlledOpen ?? isOpen;

  const setOpen = setControlledOpen ?? setState;

  const floatingProps = useFloating({
    middleware: [
      offset({
        alignmentAxis: 5,
        mainAxis: 5,
        ...(typeof offsetProps === 'object' ? offsetProps : {}),
      }),
      ...(enableFlip ? [flip({ fallbackAxisSideDirection: 'end' })] : []),
      shift(),
      ...(matchParentWidth
        ? [
            size({
              apply({ elements, rects }) {
                Object.assign(elements.floating.style, { width: `${rects.reference.width}px` });
              },
            }),
          ]
        : []),
    ],
    nodeId,
    onOpenChange: setOpen,
    open,
    placement,
    whileElementsMounted: autoUpdate,
  });

  const { context } = floatingProps;

  const hover = useHover(context, {
    enabled: triggerEvent === 'hover',
    handleClose: safePolygon({ requireIntent: false }),
  });
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const interactions = useInteractions([hover, click, dismiss, role]);

  return React.useMemo(
    () => ({
      isOverlayActive,
      open,
      root,
      setOpen,
      ...interactions,
      ...floatingProps,
    }),
    [open, root, setOpen, interactions, floatingProps, isOverlayActive]
  );
}

type ContextType = ReturnType<typeof usePopover> | null;

const PopoverContext = React.createContext<ContextType>(null);

export const usePopoverContext = () => {
  const context = React.useContext(PopoverContext);

  if (context == null) {
    throw new Error('PopoverTrigger and PopoverContent components must be wrapped in <Popover />');
  }

  return context;
};

interface PopoverTriggerProps {
  asChild?: boolean;
  children: React.ReactNode;
}

export const PopoverTrigger = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement> & PopoverTriggerProps
>(function PopoverTrigger({ children, asChild = false, ...props }, propRef) {
  const context = usePopoverContext();
  const childrenRef = (children as any).ref;
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

  // `asChild` allows the user to pass any element as the anchor
  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        'data-state': context.open ? 'open' : 'closed',
      })
    );
  }

  return (
    <button
      // The user can style the trigger based on the state
      data-state={context.open ? 'open' : 'closed'}
      ref={ref}
      type="button"
      {...context.getReferenceProps(props)}
    >
      {children}
    </button>
  );
});

const PopoverContentOverlay = ({ children, isOverlayActive }) =>
  isOverlayActive ? <FloatingOverlay lockScroll>{children}</FloatingOverlay> : children;

export const PopoverContent = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
  (props, propRef) => {
    const { className = '', style, ...restProps } = props;

    const { context: floatingContext, root, isOverlayActive, ...context } = usePopoverContext();

    const ref = useMergeRefs([context.refs.setFloating, propRef]);

    return context.open ? (
      <FloatingNode id={floatingContext.nodeId}>
        <FloatingPortal root={root}>
          <PopoverContentOverlay isOverlayActive={isOverlayActive}>
            <div
              ref={ref}
              style={{
                left: context.x ?? 0,
                position: context.strategy,
                top: context.y ?? 0,
                ...style,
              }}
              {...context.getFloatingProps({
                className: twMerge(
                  'bg-white dark:bg-darker-grey dark:text-white rounded-lg p-2 shadow-lg z-0',
                  className
                ),
                ...restProps,
              })}
            >
              {props.children}
            </div>
          </PopoverContentOverlay>
        </FloatingPortal>
      </FloatingNode>
    ) : null;
  }
);

export const PopoverComponent: React.FC<
  React.PropsWithChildren<
    Partial<
      UseFloatingOptions & {
        enableFlip?: boolean;
        isOverlayActive?: boolean;
        matchParentWidth: boolean;
        offsetProps?: OffsetOptions;
        root?: HTMLElement | null;
        triggerEvent?: 'hover' | 'click';
      }
    >
  >
> = (props) => {
  const { children, ...popoverOptions } = props;

  const popover = usePopover({ ...popoverOptions });
  return <PopoverContext.Provider value={popover}>{children}</PopoverContext.Provider>;
};

export const Popover: React.FC<React.ComponentProps<typeof PopoverComponent>> = (props) => {
  const parentId = useFloatingParentNodeId();

  // This is a root, so we wrap it with the tree
  if (parentId === null) {
    return (
      <FloatingTree>
        <PopoverComponent {...props} />
      </FloatingTree>
    );
  }

  return <PopoverComponent {...props} />;
};
