import React from 'react';

import { IIconProps } from './types';

const InstantBookingIcon: React.FC<IIconProps> = ({
  width = 24,
  className = '',
  color = 'currentColor',
}) => (
  <svg className={className} height={width} viewBox="0 0 20 20" width={width}>
    <path
      d="M4.375 5.833a.625.625 0 1 0 0-1.25.625.625 0 0 0 0 1.25ZM7.5 5.208a.625.625 0 1 1-1.25 0 .625.625 0 0 1 1.25 0ZM9.375 5.833a.625.625 0 1 0 0-1.25.625.625 0 0 0 0 1.25Z"
      fill={color}
    />
    <path
      clipRule="evenodd"
      d="M4.167 1.875A3.125 3.125 0 0 0 1.042 5v10c0 1.726 1.399 3.125 3.125 3.125h11.666c1.726 0 3.125-1.4 3.125-3.125V5c0-1.726-1.399-3.125-3.125-3.125H4.167ZM2.292 5c0-1.036.84-1.875 1.875-1.875h11.666c1.036 0 1.875.84 1.875 1.875v10c0 1.035-.84 1.875-1.875 1.875H4.167A1.875 1.875 0 0 1 2.292 15V5Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M5.208 11.458c0-1.15.933-2.083 2.084-2.083h5.416a2.083 2.083 0 0 1 0 4.167H7.292a2.083 2.083 0 0 1-2.084-2.084Zm2.084-.833a.833.833 0 0 0 0 1.667h5.416a.833.833 0 0 0 0-1.667H7.292Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

export { InstantBookingIcon };
