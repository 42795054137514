/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum QuestionnaireType {
    INSTANT_BOOK = 'INSTANT_BOOK',
    LEAD_FORM = 'LEAD_FORM',
    QUESTIONNAIRE_FORM = 'QUESTIONNAIRE_FORM',
    SCHEDULING_FORM = 'SCHEDULING_FORM',
}
