import React from 'react';

import { IIconProps } from './types';

const MoreIcon: React.FC<IIconProps> = ({ className = '', color = 'currentColor', width = 20 }) => (
  <svg className={className} height={width} viewBox="0 0 20 20" width={width}>
    <path
      clipRule="evenodd"
      d="M3.824 8.5c.825 0 1.494.669 1.494 1.494v.012a1.494 1.494 0 01-2.988 0v-.012C2.33 9.169 3 8.5 3.824 8.5zm6.171 0c.825 0 1.494.669 1.494 1.494v.012a1.494 1.494 0 01-2.988 0v-.012c0-.825.67-1.494 1.494-1.494zm6.172 0c.825 0 1.494.669 1.494 1.494v.012a1.494 1.494 0 11-2.988 0v-.012c0-.825.669-1.494 1.494-1.494z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);
export { MoreIcon };
