export enum QueryKeyEnum {
  ACTIVE_PROJECT_COUNT = 'active-projects-count',
  ACTIVE_STRIPE_SUBSCRIPTION = 'active-stripe-subscription',
  ACTIVE_STRIPE_SUBSCRIPTIONS_LIST = 'active-stripe-subscriptions-list',
  ACTIVE_SUBSCRIPTION_PRODUCT = 'active-subscription-product',
  ACTIVE_USER_SUBSCRIPTION = 'user-active-subscription',
  ALBUMS_LIST = 'albums-list',
  AVAILABILITY_EXCEPTIONS = 'availability-exceptions',
  AVAILABILITY_SETTINGS = 'availability-settings',
  BOOKING_PACKAGE_TEMPLATES_LIST = 'booking-package-templates-list',
  CALENDAR_EVENTS_LIST = 'calendar-events-list',
  CALENDARS_LIST = 'calendars-list',
  CLIENT_ACCOUNTS_LIST = 'client-accounts-list',
  CLIENT_PORTAL_SETTINGS = 'client-portal-settings',
  CLIENT_PROJECT = 'client-project',
  CLIENT_PROJECTS_LIST = 'client-projects-list',
  COLORS_LIST = 'corors-list',
  CONTACT = 'contact',
  CONTACT_BY_FIREBASE_ID = 'contact-by-firebase-id',
  CONTACT_NAMES_LIST = 'contact-names-list',
  CONTACT_SOURCES_LIST = 'contact-sources-list',
  CONTACT_TYPES_LIST = 'contact-types-list',
  CONTACTS_LIST = 'contacts-list',
  CONTRACT = 'contract',
  CONTRACT_TEMPLATE = 'contract-template',
  CONTRACT_TEMPLATES_LIST = 'contract-templates-list',
  CONTRACT_VARIABLES_LIST = 'contract-variables-list',
  CONTRACTS_LIST = 'contracts-list',
  CUSTOM_FIELDS_LIST = 'custom-fields-list',
  CUSTOM_FONTS = 'custom-fonts',
  CUSTOM_URL_AVAILABILITY = 'custom-url-availability',
  DEFAULT_SPECIALTIES = 'default-specialties',
  DUDA_SITES_LIST = 'duda-sites-list',
  DUDA_TEMPLATES_LIST = 'duda-templates-list',
  EMAIL_TEMPLATE = 'email-template',
  EMAIL_TEMPLATES_LIST = 'email-templates-list',
  FEATURE_FLAGS_LIST = 'feature-flags-list',
  GALLERIES_LIST = 'galleries-list',
  GALLERY = 'gallery',
  GENERIC_TEMPLATES = 'generic-templates',
  INSTANT_BOOKING_COUNTER = 'instant-booking-counter',
  INTEGRATIONS = 'integrations',
  INVOICE = 'invoice',
  INVOICE_TRANSACTIONS_LIST = 'invoice-transactions-list',
  INVOICES_LIST = 'invoices-list',
  LEAD = 'lead',
  LEAD_ACTIVITIES_LIST = 'lead-activities-list',
  LEAD_BLOCKS_LIST = 'lead-blocks-list',
  LEAD_INVOICE = 'lead-invoice',
  LEAD_INVOICES_LIST = 'lead-invoices-list',
  LEADS_LIST = 'leads-list',
  LEADS_NOTES_LIST = 'lead-notes-list',
  LEADS_SEARCH = 'leads-search',
  MEMBERS_LIST = 'members-list',
  MEMBERSHIP_PLAN = 'membership-plan',
  MEMBERSHIP_PLANS_LIST = 'membership-plans-list',
  NEXT_INVOICE_NUMBER = 'next-invoice-number',
  ONBOARDING_TASKS_LIST = 'onboarding-tasks-list',
  PAYOUT_TRANSACTIONS_LIST = 'payout-transactions-list',
  PORTAL_STATISTICS = 'portal-statistics',
  PROJECT = 'project',
  PROJECT_ACTIVITIES_LIST = 'project-activities-list',
  PROJECT_BLOCKS_LIST = 'project-blocks-list',
  PROJECT_CONTRACTS_LIST = 'project-contracts-list',
  PROJECT_INVOICES_LIST = 'project-invoices-list',
  PROJECT_NOTES_LIST = 'project-notes-list',
  PROJECT_SEARCH = 'project-search',
  PROJECTS_LIST = 'projects-list',
  PUBLIC_INVOICE = 'public-invoice',
  PUBLIC_PORTFOLIO = 'public-portfolio',
  QUESTIONNAIRE = 'questionnaire',
  QUESTIONNAIRES_LIST = 'questionnaires-list',
  QUICKBOOK_TAX_CODES = 'quickbook-tax-codes',
  REVIEWS_LIST = 'reviews-list',
  SQUARE_LOCATION = 'square-location',
  SQUARE_LOCATIONS_LIST = 'square-locations-list',
  STRIPE_CUSTOMER = 'stripe-customer',
  STRIPE_INVOICES_LIST = 'stripe-invoices-list',
  STRIPE_UPCOMING_INVOICE = 'stripe-upcoming-invoice',
  SUPPORTED_CURRENCIES = 'supported-currencies',
  SYNCED_QUICKBOOK_TAX_CODES = 'synced-quickbook-tax-codes',
  TAGS_POOL = 'tags-pool',
  TRANSACTION_RECEIPT = 'transaction-receipt',
  UNREAD_LEADS_COUNT = 'unread-leads-count',
  WEATHER = 'weather',
  WEBSITE_THEMES_LIST = 'website-themes-list',
  WORKFLOW_COUNT = 'workflow-count',
  WORKFLOW_OPTIONS = 'workflow-options',
  WORKFLOW_PROJECTS_LIST = 'workflow-projects-list',
  WORKFLOW_TASKS_LIST = 'workflow-tasks-list',
  WORKFLOWS_LIST = 'workflows-list',
}
