import React from 'react';

import { Dropdown } from '@bloom/ui/components/Dropdown';
import { IconButton } from '@bloom/ui/components/IconButton';
import { MoreIcon } from '@bloom/ui/components/Icons/More';
import { IIconProps } from '@bloom/ui/components/Icons/types';

import { LoaderSizeEnum, LoaderV2, LoaderVariantEnum } from '@bloom/library/components/LoaderV2';
import { PartialKey } from '@bloom/library/types/general';

const CustomTrigger = React.forwardRef<
  HTMLButtonElement,
  Partial<React.ComponentProps<typeof IconButton>> & {
    loading?: boolean;
    triggerIcon?: React.FC<IIconProps>;
  }
>((props, forwardedRef) => {
  const { loading, triggerIcon: Icon = MoreIcon, ...restProps } = props;
  return loading ? (
    <div className="relative inline-flex h-8 w-8 items-center rounded-sm">
      <LoaderV2
        data-testid={`${props['data-testid']}-loader`}
        size={LoaderSizeEnum.XS}
        variant={LoaderVariantEnum.DARK}
      />
    </div>
  ) : (
    <IconButton
      className="group-hover:text-body relative"
      data-testid={`${props['data-testid']}`}
      ref={forwardedRef}
      {...restProps}
    >
      <Icon />
    </IconButton>
  );
});

const OptionsButton: React.FC<
  PartialKey<React.ComponentProps<typeof Dropdown>, 'Trigger'> & {
    loading?: boolean;
    placeholder?: string;
    triggerIcon?: React.FC<IIconProps>;
    triggerProps?: Partial<React.ComponentProps<typeof IconButton>>;
  }
> = (props) => {
  const {
    invalid,
    loading,
    menuItems,
    name,
    onChange,
    placeholder,
    Trigger,
    triggerIcon,
    triggerProps,
    value,
    ...restDropdownProps
  } = props;

  return (
    <Dropdown
      Trigger={
        Trigger || <CustomTrigger {...triggerProps} loading={loading} triggerIcon={triggerIcon} />
      }
      initialFocus={-1}
      matchParentWidth={false}
      menuItemClassName="flex-row-reverse justify-between"
      menuItems={menuItems}
      name={name}
      onChange={onChange}
      placement="bottom-end"
      typeaheadEnabled={false}
      value={value}
      {...restDropdownProps}
    />
  );
};

export { OptionsButton };
