'use client';
import { useCallback, useMemo, useState } from 'react';

import { useMounted } from '@bloom/ui/components/hooks/useMounted';

// Proposal for use
// Most common use case is toggling modals/popups etc.
// const [isOpen, {setTrue: openModal, setFalse: closeModal}] = useToggle
// ................
// <PrimaryButton onClick={openModal}>Open</PrimaryButton>
// ........
// <Modal isShown={isOpen} onClose={closeModal}

function useToggle(initState = false): [
  boolean,
  {
    setFalse: () => void;
    setState: (state: boolean) => void;
    setTrue: () => void;
    toggle: () => void;
  },
] {
  const isMountedRef = useMounted();
  const [state, setState] = useState(initState);

  const handleToggle = useCallback(() => {
    if (isMountedRef.current) {
      setState(!state);
    }
  }, [isMountedRef, state]);

  const setTrue = useCallback(() => {
    if (isMountedRef.current) {
      setState(true);
    }
  }, [isMountedRef]);

  const setFalse = useCallback(() => {
    if (isMountedRef.current) {
      setState(false);
    }
  }, [isMountedRef]);

  return useMemo(
    () => [state, { setFalse, setState, setTrue, toggle: handleToggle }],
    [handleToggle, setFalse, setState, setTrue, state]
  );
}

export { useToggle };
