/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum QuestionType {
    ADDRESS = 'ADDRESS',
    ATTACHMENT = 'ATTACHMENT',
    BOOKING_AUTOMATION = 'BOOKING_AUTOMATION',
    BOOKING_PACKAGE = 'BOOKING_PACKAGE',
    BOOLEAN = 'BOOLEAN',
    CONTENT = 'CONTENT',
    DATE = 'DATE',
    MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
    NUMBER = 'NUMBER',
    ONE_LINE_TEXT = 'ONE_LINE_TEXT',
    PERSONAL_INFO = 'PERSONAL_INFO',
    SPECIALTY = 'SPECIALTY',
    TEXTUAL = 'TEXTUAL',
}
