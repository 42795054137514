import React from 'react';

import { twMerge } from 'tailwind-merge';

import { standardColors } from '@bloom/ui/constants/colors';
import { hexToRgba } from '@bloom/ui/utils/color';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  color?: string;
}

const IconWrapper: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { children, className, color = standardColors.black, style, ...restProps } = props;

  return (
    <div
      className={twMerge(
        'relative h-10 w-8 shrink-0 rounded-sm rounded-tr-2xl text-white',
        className
      )}
      style={{ backgroundColor: color, boxShadow: `0px 4px 8px ${hexToRgba(color, 25)}`, ...style }}
      {...restProps}
    >
      {React.isValidElement(children)
        ? React.cloneElement(children, {
            color: 'currentColor',
            width: 16,
            ...children.props,
            className: twMerge(
              'absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2',
              children.props.className
            ),
          })
        : null}
    </div>
  );
};

export { IconWrapper };
