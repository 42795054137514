import React from 'react';

import { twMerge } from 'tailwind-merge';

type TPropsBase = {
  'data-testid': string;
};

type TPropsAnchor = TPropsBase & React.AnchorHTMLAttributes<HTMLAnchorElement> & { as: 'a' };
// Button is the default tagName
type TPropsButton = TPropsBase & React.ButtonHTMLAttributes<HTMLButtonElement> & { as?: 'button' };

// Pass item to button only.
// It does not make sense to pass item to an anchor
// anchor should only point to a link in href attribute
type IPropsWithItem<T> = Omit<TPropsButton, 'onClick'> & {
  item: T;
  onClick: (item: T) => void;
};

type IPropsWithoutItem = TPropsAnchor | TPropsButton;

type TProps<T> = React.PropsWithChildren<IPropsWithItem<T> | IPropsWithoutItem>;

function HyperlinkInner<T>(props: TProps<T>, forwardedRef: React.ForwardedRef<HTMLElement>) {
  // exclude item prop from the rest, so it is not passed to the DOM element
  const { as = 'button', className, item, onClick, ...restProps } = props;

  function handleClick(e) {
    // anchor element does not have disabled attribute
    if ('disabled' in props && props.disabled) {
      return;
    }

    // if item is passed, call onClick with item
    if ('item' in props) {
      props.onClick(props.item);
      return;
    }

    if (typeof props.onClick === 'function') {
      props.onClick(e);
    }
  }

  return React.createElement(as, {
    className: twMerge(
      'font-medium text-sm inline-flex cursor-pointer items-center justify-center hover:underline gap-2 decoration-1 underline-offset-[3px] whitespace-nowrap',
      'disabled' in props && props.disabled ? 'cursor-not-allowed' : null,
      className
    ),
    ref: forwardedRef,
    ...(as === 'button' && { type: 'button' }),
    ...restProps,
    // keep onClick last so it is not overwritten from props
    // Also use ternary operator to avoid NestJs error
    // "Error: Event handlers cannot be passed to Client Component props."
    // in case of using the component as an anchor tag without onClick
    onClick: onClick ? (handleClick as TProps<T>['onClick']) : undefined,
  });
}

const Hyperlink = React.forwardRef(HyperlinkInner) as <T>(
  props: TProps<T> & { ref?: React.ForwardedRef<HTMLElement> }
) => ReturnType<typeof HyperlinkInner>;

export { Hyperlink };
