import React from 'react';

import { IIconProps } from './types';

const CopyV2Icon: React.FC<IIconProps> = ({
  className = '',
  color = 'currentColor',
  width = 20,
}) => (
  <svg className={className} fill="none" height={width} viewBox="0 0 20 20" width={width}>
    <path
      clipRule="evenodd"
      d="M3.3335 2.49998C3.11248 2.49998 2.90052 2.58778 2.74424 2.74406C2.58796 2.90034 2.50016 3.1123 2.50016 3.33331V10.8333C2.50016 11.0543 2.58796 11.2663 2.74424 11.4226C2.90052 11.5788 3.11248 11.6666 3.3335 11.6666H4.16683C4.62707 11.6666 5.00016 12.0397 5.00016 12.5C5.00016 12.9602 4.62707 13.3333 4.16683 13.3333H3.3335C2.67045 13.3333 2.03457 13.0699 1.56573 12.6011C1.09689 12.1322 0.833496 11.4964 0.833496 10.8333V3.33331C0.833496 2.67027 1.09689 2.03439 1.56573 1.56555C2.03457 1.0967 2.67045 0.833313 3.3335 0.833313H10.8335C11.4965 0.833313 12.1324 1.09671 12.6013 1.56555C13.0701 2.03439 13.3335 2.67027 13.3335 3.33331V4.16665C13.3335 4.62688 12.9604 4.99998 12.5002 4.99998C12.0399 4.99998 11.6668 4.62688 11.6668 4.16665V3.33331C11.6668 3.1123 11.579 2.90034 11.4228 2.74406C11.2665 2.58778 11.0545 2.49998 10.8335 2.49998H3.3335ZM9.16683 8.33331C8.70659 8.33331 8.3335 8.70641 8.3335 9.16665V16.6666C8.3335 17.1269 8.70659 17.5 9.16683 17.5H16.6668C17.1271 17.5 17.5002 17.1269 17.5002 16.6666V9.16665C17.5002 8.70641 17.1271 8.33331 16.6668 8.33331H9.16683ZM6.66683 9.16665C6.66683 7.78593 7.78612 6.66665 9.16683 6.66665H16.6668C18.0475 6.66665 19.1668 7.78593 19.1668 9.16665V16.6666C19.1668 18.0474 18.0475 19.1666 16.6668 19.1666H9.16683C7.78612 19.1666 6.66683 18.0474 6.66683 16.6666V9.16665Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);
export { CopyV2Icon };
