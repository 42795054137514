import { twMerge } from 'tailwind-merge';

import style from './CustomScroll.module.css';

const CustomScroll: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
  const { children, className, ...restProps } = props;

  return (
    <div
      className={twMerge(
        style.scroller,
        'relative max-h-full overflow-y-auto overscroll-contain',
        className
      )}
      {...restProps}
    >
      {children}
    </div>
  );
};

export { CustomScroll };
