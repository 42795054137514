import React from 'react';

import { IIconProps } from './types';

const ArrowIcon: React.FC<IIconProps> = ({
  width = 20,
  className = '',
  color = 'currentColor',
  rotate = 0,
}) => (
  <svg className={className} height={width} viewBox="0 0 20 20" width={width}>
    <path
      clipRule="evenodd"
      d="M9.41 3.577a.833.833 0 011.18 0l5.833 5.834a.833.833 0 010 1.178l-5.834 5.834a.833.833 0 01-1.178-1.179l4.41-4.41H4.168a.833.833 0 110-1.667h9.655L9.41 4.756a.833.833 0 010-1.179z"
      fill={color}
      fillRule="evenodd"
      transform={`rotate(${rotate} 10 10)`}
    />
  </svg>
);

export { ArrowIcon };
