'use client';
import { useMemo } from 'react';

import { UseQueryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { AccountRequest } from '@bloom/codegen/models/AccountRequest';
import { AccountResponse } from '@bloom/codegen/models/AccountResponse';
import { ResetAccountTrialRequest } from '@bloom/codegen/models/ResetAccountTrialRequest';

import { useInfoMessage } from '@bloom/library/components/FlashMessageV2/info-message-context';
import { AsyncStatusEnum, useFetch } from '@bloom/library/components/hooks/useFetch';
import { getUserHostname } from '@bloom/library/utils/browser';

import { SharedQueryKeyEnum } from './interface';
import { useMe } from './useMe';

export function useAccountsList() {
  const { get } = useFetch();

  async function fetchAccountsList() {
    const response = await get<{ accounts: Array<AccountResponse> }>('/api/accounts', {
      headers: { 'x-account': undefined },
    });

    if (response.status === AsyncStatusEnum.SUCCESS) {
      return response.data.accounts;
    }

    return [];
  }

  return useQuery({ queryFn: fetchAccountsList, queryKey: [SharedQueryKeyEnum.ACCOUNTS_LIST] });
}

export function useAccount(
  accountId = '',
  queryOptions: Partial<
    UseQueryOptions<AccountResponse | null, unknown, AccountResponse | null, string[]>
  > = {}
) {
  const { get, handleDelete, patch, post } = useFetch();

  const { me, updateMe } = useMe();

  const computedAccountId = accountId || me?.defaultAccountId || '';

  const queryClient = useQueryClient();
  const { showErrorMessageFromResponse } = useInfoMessage();

  async function fetchAccount() {
    const response = await get<{ account: AccountResponse }>(`/api/accounts/${computedAccountId}`);

    if (response.status === AsyncStatusEnum.SUCCESS) {
      return response.data.account;
    }

    return null;
  }

  const { data, isFetching, refetch } = useQuery({
    enabled: !!computedAccountId,
    queryFn: fetchAccount,
    queryKey: [SharedQueryKeyEnum.ACCOUNT, computedAccountId],
    ...queryOptions,
  });

  async function createAccount(request: AccountRequest) {
    const response = await post<{ account: AccountResponse }, AccountRequest>(
      '/api/accounts',
      request
    );

    return response;
  }

  const { isPending: isCreatingAccount, mutateAsync: createAccountMutation } = useMutation({
    mutationFn: createAccount,
    onSuccess: async (res) => {
      if (res.status === AsyncStatusEnum.SUCCESS) {
        updateMe({ defaultAccountId: res.data.account.id }).then((response) => {
          if (response.status === AsyncStatusEnum.SUCCESS) {
            window.location.pathname = '/portal';
          }

          if (response.status === AsyncStatusEnum.ERROR) {
            showErrorMessageFromResponse(response);
          }
        });
      }

      if (res.status === AsyncStatusEnum.ERROR) {
        showErrorMessageFromResponse(res);
      }
      return res;
    },
  });

  async function updateAccount(request: AccountRequest) {
    const response = await patch<{ account: AccountResponse }, AccountRequest>(
      `/api/accounts/${computedAccountId || 'default'}`,
      request
    );

    return response;
  }

  const { isPending: isUpdatingAccount, mutateAsync: updateAccountMutation } = useMutation({
    mutationFn: updateAccount,
    onSuccess: async (res) => {
      if (res.status === AsyncStatusEnum.SUCCESS) {
        queryClient.setQueryData<AccountResponse>(
          [SharedQueryKeyEnum.ACCOUNT, computedAccountId || 'default'],
          () => res.data.account
        );
        queryClient.invalidateQueries({ queryKey: [SharedQueryKeyEnum.ACCOUNTS_LIST] });
      }

      if (res.status === AsyncStatusEnum.ERROR) {
        showErrorMessageFromResponse(res);
      }
      return res;
    },
  });

  async function resetTrial() {
    const response = await post<{ account: AccountResponse }, ResetAccountTrialRequest>(
      `/api/accounts/${computedAccountId || 'default'}/reset-trials`,
      { trialDays: 14 },
      process.env.BLOOM_TEST_RESET_TRIAL_HEADER
        ? { headers: { [process.env.BLOOM_TEST_RESET_TRIAL_HEADER]: 'true' } }
        : undefined
    );

    return response;
  }

  const { isPending: isResettingAccount, mutateAsync: resetAccountMutation } = useMutation({
    mutationFn: resetTrial,
    onSuccess: async (res) => {
      if (res.status === AsyncStatusEnum.SUCCESS) {
        queryClient.setQueryData<AccountResponse>(
          [SharedQueryKeyEnum.ACCOUNT, computedAccountId || 'default'],
          () => res.data.account
        );
        queryClient.invalidateQueries({ queryKey: [SharedQueryKeyEnum.ACCOUNTS_LIST] });
      }

      if (res.status === AsyncStatusEnum.ERROR) {
        showErrorMessageFromResponse(res);
      }
      return res;
    },
  });

  async function deleteAccount(accountIdArgument: string) {
    const response = await handleDelete(`/api/accounts/${accountIdArgument}`);

    return response;
  }

  const { isPending: isDeletingAccount, mutateAsync: deleteAccountMutation } = useMutation({
    mutationFn: deleteAccount,
    onSuccess: async (res) => {
      if (res.status === AsyncStatusEnum.SUCCESS) {
        queryClient.invalidateQueries({ queryKey: [SharedQueryKeyEnum.ACCOUNTS_LIST] });
      }

      if (res.status === AsyncStatusEnum.ERROR) {
        showErrorMessageFromResponse(res);
      }
      return res;
    },
  });

  return useMemo(
    () => ({
      account: data,
      createAccount: createAccountMutation,
      deleteAccount: deleteAccountMutation,
      isAccountOwner: me?.id === data?.ownerId,
      isCreatingAccount,
      isDeletingAccount,
      isFetching,
      isResettingAccount,
      isUpdating: isUpdatingAccount,
      refetch,
      resetTrial: resetAccountMutation,
      updateAccount: updateAccountMutation,
    }),
    [
      data,
      createAccountMutation,
      deleteAccountMutation,
      me?.id,
      isCreatingAccount,
      isDeletingAccount,
      isFetching,
      isResettingAccount,
      isUpdatingAccount,
      refetch,
      resetAccountMutation,
      updateAccountMutation,
    ]
  );
}

export function useAccountHostname() {
  const { account } = useAccount();

  if (account) {
    const { customUrl = '', domain = '' } = account.settings;
    const hostname = getUserHostname(domain, customUrl);
    return hostname;
  }

  return '';
}
