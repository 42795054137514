import { useCallback, useMemo } from 'react';

import { usePathname, useRouter, useSearchParams as nextUseSearchParams } from 'next/navigation';

interface NavigateOptions {
  scroll?: boolean;
}

export const useSearchParams = () => {
  // Added new URLSearchParams() as a fallback for the test environment
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = nextUseSearchParams() || new URLSearchParams();
  const params = useMemo(() => new URLSearchParams(searchParams.toString()), [searchParams]);

  const setSearchParams = useCallback(
    (newSearchParams: URLSearchParams, options?: NavigateOptions) => {
      router.push(`${pathname}?${newSearchParams.toString()}`, {
        scroll: false,
        ...options,
      });
    },
    [pathname]
  );

  return useMemo(() => [params, setSearchParams] as const, [params, setSearchParams]);
};
