import { SignatureOptionsResponse } from '@bloom/codegen/models/SignatureOptionsResponse';

export interface ITemplateSignature {
  body: string;
  options: SignatureOptionsResponse;
  renderedBody: string;
}

export enum ClientInfoFieldEnum {
  FIRST_NAME = 'First Name',
  LAST_NAME = 'Last Name',
  EMAIL_ADDRESS = 'Email Address',
  PHONE_NUMBER = 'Phone Number',
  FULL_NAME = 'Full Name',
  COMPANY = 'Company',
}
export type ClientInfoFieldNames =
  | ClientInfoFieldEnum.EMAIL_ADDRESS
  | ClientInfoFieldEnum.FIRST_NAME
  | ClientInfoFieldEnum.FULL_NAME
  | ClientInfoFieldEnum.LAST_NAME
  | ClientInfoFieldEnum.PHONE_NUMBER
  | ClientInfoFieldEnum.COMPANY;

export const ClientInfoTokenMap: { [k in ClientInfoFieldNames]: string } = {
  [ClientInfoFieldEnum.EMAIL_ADDRESS]: '{{CLIENT_EMAIL}}',
  [ClientInfoFieldEnum.FIRST_NAME]: '{{CLIENT_FIRST_NAME}}',
  [ClientInfoFieldEnum.FULL_NAME]: '{{CLIENT_FULL_NAME}}',
  [ClientInfoFieldEnum.LAST_NAME]: '{{CLIENT_LAST_NAME}}',
  [ClientInfoFieldEnum.PHONE_NUMBER]: '{{CLIENT_PHONE}}',
  // we don't need support client company for templates, just keeping this here
  // for future reference to see what client info fields we have
  [ClientInfoFieldEnum.COMPANY]: '{{CLIENT_BUSINESS_NAME}}',
};

export const ClientInfoDisplayNameMap: { [k in ClientInfoFieldNames]: string } = {
  [ClientInfoFieldEnum.EMAIL_ADDRESS]: '(Client Email)',
  [ClientInfoFieldEnum.FIRST_NAME]: '(Client First Name)',
  [ClientInfoFieldEnum.FULL_NAME]: '(Client Full Name)',
  [ClientInfoFieldEnum.LAST_NAME]: '(Client Last Name)',
  [ClientInfoFieldEnum.PHONE_NUMBER]: '(Client Phone Number)',
  [ClientInfoFieldEnum.COMPANY]: '(Client Business Name)',
};
