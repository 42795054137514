import React from 'react';

import { IIconProps } from '@bloom/library/components/Icon/types';

const CheckIcon: React.FC<IIconProps> = ({
  className = '',
  color = 'currentColor',
  width = 16,
}) => {
  return (
    <svg className={className} fill="none" height={width} viewBox="0 0 16 16" width={width}>
      <path
        clipRule="evenodd"
        d="M13.805 3.529c.26.26.26.682 0 .942L6.47 11.805a.667.667 0 0 1-.942 0L2.195 8.47a.667.667 0 1 1 .943-.942L6 10.39l6.862-6.861c.26-.26.682-.26.943 0Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export { CheckIcon };
