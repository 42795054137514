import React from 'react';

import { twMerge } from 'tailwind-merge';

export const ModalFooter: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
  const { className, children, ...restProps } = props;
  return (
    <footer className={twMerge('flex items-center justify-start gap-3', className)} {...restProps}>
      {children}
    </footer>
  );
};
